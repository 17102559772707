import logo from "../assets/InventlyLogo1024.png";

export default function Header() {
  return (
    <div className="container-fluid text-center m-0 p-0">
      <div
        className="m-0 p-0"
        style={{
          width: "100vw",
          height: "100vh",
          background: `url("${logo}") no-repeat center center/cover`,
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          className="d-flex flex-column align-items-center justify-content-center m-0 p-0"
        >
          <h1 className="display-1">Invently</h1>
          <h4 className="mx-5">
            Strategic Innovation for the Internet of Everything.
          </h4>
        </div>
      </div>
    </div>
  );
}
