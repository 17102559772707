import Footer from "./components/Footer";
import Header from "./components/Header";
import Signup from "./components/Signup";

function App() {
  return (
    <div>
      <Header />
      <Signup />
      <Footer />
    </div>
  );
}

export default App;
